<template>
    <div class="rdvmaf-caroussel bg-black" v-if="currentEvenement">
        <transition name="fade" mode="out-in">
            <div class="caroussel-item" :key="currentIndex" @click="onClick">
                <div class="image col-12 col-xl-8">
                    <img :src="photo" class="picture" />
                </div>
                <div class="aside-carroussel col-12 col-xl-4">
                    <div class="description">
                    <div class="summary">
                            <div class="d-inline-flex gap-3">
                                <type-evenement-tag :typeEvenement="currentEvenement.type" :evenement="currentEvenement" />
                                <rdvmaf-inscrit-tag v-if="inscrit(currentEvenement.evenementId) && !isRemoved(currentEvenement.evenementId) && !isReported(currentEvenement.evenementId)" />
                                <rdvmaf-annule-tag v-else-if="isRemoved(currentEvenement.evenementId)" />
                                <rdvmaf-reporte-tag v-else-if="isReported(currentEvenement.evenementId)" />
                            </div>
                            <div class="date">
                                {{ date }}
                            </div>
                            <div class="title">
                                {{ currentEvenement.sujet }}
                            </div>
                            <div class="subtitle">
                                {{ currentEvenement.publicCible }}
                            </div>
                        </div>
                    </div>
                    <div class="sub" :class="{'hidden' : isSmallScreen}"  v-if="!isRemoved(currentEvenement.evenementId)">
                        <button @click="navigateToDetails" class="btn btn-outline-light">Je m'inscris</button>
                    </div>
                    <div class="bottom" :class="{'hidden' : isSmallScreen}">
                        <div class="navigation">
                            <div class="nav-item" :class="{active : currentIndex === index }" v-for="(el, index) in evenements" :key="`nav-item-${index}`" @click="() => setIndex(index)">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">

import { Prop, Component, Vue } from 'vue-property-decorator';
import { IEvenement } from '@/models/evenement';
import TypeEvenementTag from '@/shared/type-evenement-tag.vue';
import RdvmafInscritTag from '@/shared/rdvmaf-inscrit-tag.vue';
import { imageUrl } from '@/models/file';
import { redirectToRoute } from '@/tools/rdvmaf-redirection';
import RdvmafReporteTag from '@/shared/rdvmaf-reporte-tag.vue';
import RdvmafAnnuleTag from '@/shared/rdvmaf-annule-tag.vue';
import { convertTimeToString } from '@/tools/time-helper';
import { vxm } from '@/store';
import { addDays, getLongDateString } from '@/tools/date-helper';
@Component({
    components: {
        TypeEvenementTag,
        RdvmafInscritTag,
        RdvmafReporteTag,
        RdvmafAnnuleTag
    }
})
export default class EvenementCaroussel extends Vue {
    @Prop({ required: true })
    private evenements!: IEvenement[];
    private currentIndex: number = 0;
    private previousChange: number | null = null;
    private isSmallScreen: boolean = false;
    private intervalId: number | null = null;

    public created() {
        this.resize();
        window.addEventListener('resize', this.resize);
    }

    private resize() {
        if (screen.width < 601) {
            this.isSmallScreen = true;
            this.stopIncrement();
        } else {
            this.increment();
            this.isSmallScreen = false;
        }
    }

    public setIndex(index: number) {
        this.previousChange = new Date().getTime();
        if (index < this.evenements.length) {
            this.currentIndex = index;
        }
    }

    private increment() {
        if (this.intervalId == null) {
            this.intervalId = setInterval(() =>{
                const now = new Date().getTime();
                if (!this.previousChange || (now - this.previousChange) / 1000 >= 5) {
                    this.setIndex((this.currentIndex + 1) % this.evenements.length)
                }
            }, 5000);
        }
    }

    private stopIncrement() {
        if (this.intervalId !== null) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    private isRemoved(evenementId: number) {
        return this.inscrit(evenementId) && this.evenements.find(x => x.evenementId === evenementId)?.status.code === 'ANN';
    }

    private isReported(evenementId: number) {
        return this.inscrit(evenementId) && this.evenements.find(x => x.evenementId === evenementId)?.isReported;
    }

    get currentEvenement(): IEvenement | null {
        return this.evenements.length > this.currentIndex ? this.evenements[this.currentIndex] : null;
    }

    get photo(): string | null {
        return this.currentEvenement?.imageDescriptionFileId ? imageUrl(this.currentEvenement.imageDescriptionFileId) : '';
    }

    get date(): string {
        if (this.currentEvenement?.dateEvenement) {
            const local ='fr-FR';
            const dateEvenement = new Date(this.currentEvenement.dateEvenement);
            const date = getLongDateString(dateEvenement);
            const dateFinEvenement = this.currentEvenement.dateFin !== null ? new Date(this.currentEvenement.dateFin) : null;
            const endDateEvenement = dateFinEvenement !== null  && dateFinEvenement.getDate() !== dateEvenement.getDate() ? dateFinEvenement
                                    : this.currentEvenement.isMultiDate  
                                    ? addDays(this.currentEvenement.nombreJour - 1, new Date(dateEvenement))
                                    : null;
            const endDay = getLongDateString(endDateEvenement);

            return `${endDateEvenement ? 'Du ': ''}${date[0].toUpperCase()}${date.slice(1)} ${endDateEvenement ? ' au ' + endDay[0].toUpperCase() + endDay.slice(1) : ''} - ${convertTimeToString(dateEvenement)}`;
        }
        return '';
    }

    public inscrit(evenementId: number): boolean {
        return vxm.subscriptions.getSubscriptions.some(x => x.evenementId === evenementId);
    }

    public navigateToDetails(): void {
        if (this.currentEvenement) {
            redirectToRoute(`Details/${this.currentEvenement.evenementId}`)
        }
    }

    public onClick() {
        if (this.isSmallScreen) {
            this.navigateToDetails();
        }
    }

    public unmounted() {
        this.stopIncrement();
    }
}
</script>

<style lang="scss">
@import '@/styles';

.fade-enter-active, .fade-leave-active {
  transition: 300ms ease-in;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.rdvmaf-caroussel {
    width: 100%;

    .caroussel-item {
        display: flex;
        flex-wrap: wrap;
        max-height: inherit;

        .image {
            overflow: hidden;
            padding: 0px;

            .picture {
                width: 100%;
                object-fit: cover;
                object-position: top;
                aspect-ratio: 16 / 9;
            }
        }

        .aside-carroussel {
            color: $color-white;
            padding: 3.8rem 4.2rem;

            .description {
                padding-bottom: 2.4rem;

                .date {
                    margin-top: 24px;
                    color: $color-green;
                    font-weight: 600;
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                }

                .title {
                    font-family: $font-museo-slab;
                    font-weight: 700;
                    font-size: 2.8rem;
                    line-height: 3.6rem;
                    margin-bottom: .8rem;
                }

                .subtitle {
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                    color: rgba(255, 255, 255, 0.7);
                }
            }

            .sub {
                .btn {
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                    padding: .8rem 3.6rem;
                }
            }

            .bottom {
                @include media-breakpoint-up(xl) {
                    position: absolute;
                    bottom: 0;
                    padding-bottom: 3rem;
                }
                @include media-breakpoint-down(xl) {
                    .navigation {
                        justify-content: center;
                        padding-top: 2.4rem;
                    }
                }
                .navigation {
                    display: flex;
                    margin-top: 1rem;
                    .nav-item {
                        margin: .8rem;
                        height: 1.2rem;
                        width: 1.2rem;
                        background: none;
                        opacity: 0.4;
                        border: 2px solid #FFFFFF;
                        border-radius: 90%;
                        cursor: pointer;

                        &.active {
                            background: #FFFFFF;
                        }
                    }
                }
            }
        }
    }

}
</style>