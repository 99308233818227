<template>
    <div class="card" @click="navigateToDetails">
        <img :src="photo" :class="{ 'removed': isRemoved }" class="card-img-top aspect-video" />
        <div class="card-body">
            <div class="badges">
                <type-evenement-tag :typeEvenement="evenement.type" :evenement="evenement" />
                <rdvmaf-inscrit-tag v-if="inscrit && !isRemoved && !isReported" />
                <rdvmaf-annule-tag v-else-if="isRemoved && !isReported" />
                <rdvmaf-reporte-tag v-else-if="isReported" />
            </div>
            <div class="card-text text-green-dark fw-bold h4 date">
                {{ dateEvenement }}
            </div>
            <div class="card-title h4">
                {{ evenement.sujet }}
            </div>
        </div>
        <div class="card-footer bg-white" v-if="evenement.publicCible">
            <div>
                <i class="maficon-bullseye-arrow text-gray-500 fs-14"></i>
                <span class="text-truncate">{{ evenement.publicCible }}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { IEvenement } from '@/models/evenement';
import { Component, Vue, Prop } from 'vue-property-decorator';
import TypeEvenementTag from '@/shared/type-evenement-tag.vue';
import { redirectToRoute } from '@/tools/rdvmaf-redirection';
import RdvmafInscritTag from '@/shared/rdvmaf-inscrit-tag.vue';
import RdvmafReporteTag from '@/shared/rdvmaf-reporte-tag.vue';
import RdvmafAnnuleTag from '@/shared/rdvmaf-annule-tag.vue';
import RdvmafIcon from '@/shared/rdvmaf-icon.vue';
import { vxm } from '@/store';
import { convertTimeToString } from '@/tools/time-helper';
import { addDays } from '@/tools/date-helper';
import { imageUrl } from '@/models/file';
@Component({
    components: {
        TypeEvenementTag,
        RdvmafInscritTag,
        RdvmafReporteTag,
        RdvmafAnnuleTag,
        RdvmafIcon
    }
})
export default class EvenementItem extends Vue {
    @Prop({ required: true })
    private evenement!: IEvenement;

    @Prop({ required: true })
    private isSmallScreen!: boolean;

    get dateEvenement(): string {
        if (this.evenement.dateEvenement) {
            const local = 'fr-FR';
            const dateEvenement = new Date(this.evenement.dateEvenement);
            const options = {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        };
            const startDateString = dateEvenement.toLocaleDateString(local,options);
            let startTime = convertTimeToString(dateEvenement);

            let endDateString = '';
            let endTime = '';
            let startDayFormatted = '';
            let endDayFormatted = '';

            const dateFinEvenement = this.evenement.dateFin !== null ? new Date(this.evenement.dateFin) : null;
            const endDateEvenement = dateFinEvenement !== null && dateFinEvenement.getDate() !== dateEvenement.getDate() ? dateFinEvenement
                : this.evenement.isMultiDate ? addDays(this.evenement.nombreJour - 1, new Date(dateEvenement))
                    : null;
            if (endDateEvenement) {
                endDateString = endDateEvenement.toLocaleDateString(local, options);
                endTime = convertTimeToString(endDateEvenement);
                endDayFormatted = `au ${endDateString} - ${endTime}`;
                startDayFormatted = `Du ${startDateString} - ${startTime}`;
            } else {
                const capitalizedDay = startDateString.charAt(0).toUpperCase() + startDateString.slice(1);
                startDayFormatted = `${capitalizedDay} - ${startTime}`;
            }

            return `${startDayFormatted} ${endDayFormatted}`.trim();
        }
        return 'À venir';
    }





    get photo(): string | null {
        if (this.evenement.imageDescriptionFileId) {
            return imageUrl(this.evenement.imageDescriptionFileId);
        }
        return 'https://static.rdv-maf.fr/img/logo.png';
    }

    get inscrit(): boolean {
        return vxm.subscriptions.getSubscriptions.some(x => x.evenementId === this.evenement.evenementId);
    }

    get isReported(): boolean {
        return this.evenement.isReported && vxm.subscriptions.getSubscriptions.some(x => x.evenementId === this.evenement.evenementId);
    }

    get isRemoved(): boolean {
        return this.evenement.status != null && this.evenement.status.code == 'ANN';
    }

    public navigateToDetails(): void {
        redirectToRoute(`Details/${this.evenement.evenementId}`);
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles';
</style>
