
<template>
    <div class="liste-evenement-footer">
        <img :src="imageUrl" class="bg-image" />
        <div class="content">
            <div class="text-content">
                <div class="title">Envie de revoir les webinaires quand vous le souhaitez ?</div>
                <div class="text">La MAF vous propose désormais un catalogue de replays à visionner chaque fois que vous aurez besoin d’informations et de conseils.</div>
                <a :href="url" class="link">
                    <div class="link-text">Découvrir nos replays</div>
                    <rdvmaf-icon icon="arrow-right" />
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from "vue-property-decorator";
    import RdvmafIcon from '@/shared/rdvmaf-icon.vue';
    import { vxm } from "@/store";

    const defaultFooterImage = `${window.location.origin}/assets/images/footer.png`;
    const smallFooterImage = `${window.location.origin}/assets/images/footer-small.png`;

    @Component({
        components: {
            RdvmafIcon
        }
    })
    export default class ListeEvenementsFooter extends Vue {
        @Prop({ required: false, default: () => defaultFooterImage })
        private image!: string;

        private isSmallScreen: boolean = false;

        public created() {
            window.addEventListener('resize', this.resize);
            this.resize();
        }

        private resize() {
            this.isSmallScreen = screen.width < 1200;
        }

        get url(): string | null {
            return '/replays';
        }

        get imageUrl(): string {
            if (this.image === defaultFooterImage) {
                return this.isSmallScreen ? smallFooterImage : defaultFooterImage;
            }
            return this.image;
        }

    }
</script>

<style lang="scss">
    @import '@/styles';

    .liste-evenement-footer {
        overflow: hidden;
        position: relative;
        background: #1E1E1E;
        margin: 15px auto;

        .bg-image {
            position: absolute;
            z-index: 9;
            width: 100%;
            object-fit: cover;
        }

        .content {
            width: 100%;
            height: inherit;
            flex-direction: column;
            z-index: 10;
            position: absolute;
            display: flex;
            color: white;

            .text-content {
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                .title {
                    font-family: $museo-slab;
                }

                .text {
                    padding-top: 16px;
                    text-justify: inter-word;
                    font-family: $museo-sans;
                }

                .link {
                    margin-top: 16px;
                    font-family: $museo-sans;
                    color: $c-main;
                    font-weight: 600;
                    display: flex;
                    align-items: center;

                    .link-text {
                        margin-right: 5px;
                    }

                    .rdvmaf-icon {
                        transition: transform 250ms;
                    }

                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;

                        .rdvmaf-icon {
                            transform: translateX(10px);
                        }
                    }
                }
            }
        }

        @include media-breakpoint-up(xl) {
            height: 272px;

            .bg-image {
                left: 0;
                max-height: 272px;
            }

            .content {
                background: linear-gradient(to right, #1E1E1E00, #1E1E1EFF 40%, #1E1E1EFF);
                align-items: flex-end;
                padding: 45px;

                .text-content {
                    width: 60%;

                    .title {
                        width: match-parent;
                        color: white;
                        font-size: 24px;
                        line-height: 32px;
                    }

                    .text {
                        font-size: 15px;
                    }

                    .link {
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
        }

        @include media-breakpoint-down(xl) {
            height: 42vh;
            max-width: 90vw;

            .bg-image {
                bottom: 0;
            }

            .content {
                background: linear-gradient(to top, #1E1E1E00, #1E1E1EFF 50%, #1E1E1EFF);
                padding: 5vmin 7vmin;

                .text-content {
                    line-height: 3vh;

                    .title {
                        font-size: 17px;
                    }

                    .text {
                        font-size: 16px;
                    }

                    .link {
                        font-size: 14px;
                    }
                }
            }
        }
    }
</style>