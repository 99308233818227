<template>
    <div class="cards">
        <evenement-item v-for="evenement in evenements" :key="evenement.webinaireId" :evenement="evenement" :isSmallScreen="isSmallScreen" />
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IEvenement } from '@/models/evenement';
import EvenementItem from './evenement-item.vue';

@Component({
    components: {
        EvenementItem
    }
})
export default class ListeEvenements extends Vue {
    @Prop({ required: true })
    private evenements!: IEvenement[];
    private eventsPerLine = 4;

    private isSmallScreen: boolean = false;

    get eventLines(): IEvenement[][] {
        let result: IEvenement[][] = [];
        let currentRow: IEvenement[] = [];
        for (let index = 0; index < this.evenements.length; index++) {
            const currentEvenement = this.evenements[index];
            if (currentRow.length == this.eventsPerLine) {
                result.push(currentRow);
                currentRow = [];
            }
            else {
                currentRow.push(currentEvenement);
            }
        }
        if (currentRow.length > 0) {
            result.push(currentRow);
        }
        return result;
    }

    created() {
        this.resize();
        window.addEventListener('resize', this.resize);
    }

    resize() {
        this.isSmallScreen = screen.width < 601;
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles";
</style>
