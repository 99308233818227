<template>
    <div>
        <div id="inouit-intro" class="inouit-intro">
            <div class="container inouit-container inouit-intro-content d-flex justify-content-center">
                <div class="container inouit-container inouit-intro-content no-transform">
                    <evenement-caroussel :evenements="carouselItems"/>
                </div>
            </div>
        </div>
        <div id="inouit-main-content" class="inouit-main-content">
            <div class="container inouit-container inouit-main-container">
                <main role="main" class="pb-3">
                    <div class="no-event-div" v-if="evenements.length == 0 && !loading">
                        <p class="regular-text">Le programme des prochains webinaires sera disponible très prochainement.
                        En attendant vous pouvez revoir les webinaires passés dans votre <a href="/replays">espace replay</a> (accès réservé aux adhérents MAF) ou découvrir nos autres événements sur <a href=" https://www.maf.fr/liste-evenements" target="_blank">maf.fr</a>. </p>
                        <p class="regular-text">A très vite ! <br/>
                        L’équipe MAF</p>
                    </div>
                    <div class="rdvmaf-accueil">
                        <div class="rdvmaf-accueil-content">
                            <div class="rdvmaf-accueil-spinner" v-if="loading">
                                <rdvmaf-spinner class="rdvmaf-accueil-spinner" />
                            </div>
                            <div class="liste-title" v-if="evenements.length > 0">
                                Nos événements à venir
                            </div>

                            <liste-evenements :evenements="evenements"/>
                        </div>
                        <div class="row">
                            <div class="col-md-9 order-md-1 order-2">
                                <liste-evenements-footer :image="footerImage" />
                            </div>
                            <div class="col-md-3 order-md-2 order-1">
                                <div class="attestation-link">
                                    <rdvmaf-icon icon="attestation" width="30" height="30"/>
                                    <p class="large-text bold-text">Retrouvez vos attestations de formations</p>
                                    <p class="small-text">Les webinaires et les ateliers proposés par la MAF comptent dans le décompte de vos heures de formations obligatoires.</p>
                                    <a href="/attestations" class="medium-text">Télécharger vos attestations <rdvmaf-icon icon="arrow-right" width="16" height="16"/></a>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import EvenementCaroussel from '@/components/evenement/evenement-caroussel.vue';
import ListeEvenementsFooter from '@/components/evenement/liste-evenements-footer.vue';
import ListeEvenements from '@/components/evenement/liste-evenements.vue';
import { IEvenement } from '@/models/evenement';
import RdvmafIcon from '@/shared/rdvmaf-icon.vue';
import RdvmafSpinner from '@/shared/rdvmaf-spinner.vue';
import evenementService, { IEvenementFilter, IPaginatedEvenement } from '@/wapi/evenement-service';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        ListeEvenements,
        RdvmafSpinner,
        ListeEvenementsFooter,
        EvenementCaroussel,
        RdvmafIcon
    }
})
export default class Accueil extends Vue {
    public loading: boolean = false;
    private paginatedEvenements: IPaginatedEvenement[] = [];
    private filtres: IEvenementFilter = {
        numeroPage: 1,
        taillePage: 100,
        filtreStr: '',
        typeEvenementIds: [],
        statusPublicationIds: [],
        intervenantIds: [],
        dureeMax: null,
        dateDebut: new Date(),
        dateFin: null,
        sortDescending: false
    };
    private currentPage = 1;

    created() {
        this.getEvenements();
    }

    private async getEvenements() {
        this.loading = true;
        this.paginatedEvenements = [];
        const filters: IEvenementFilter = this.filtres;
        let page: IPaginatedEvenement | null;
        do {
            page = await evenementService.getByFilter(filters);
            if (page) {
                this.paginatedEvenements.push(page);
                if (this.currentPage === filters.numeroPage) {
                    this.loading = false;
                }
                filters.numeroPage = filters.numeroPage! + 1;
            }
            this.loading = false;
        } while (page && page.pageNumber < page.totalPageNumber);
        this.loading = false;
    }

    get evenements() {
        return this.paginatedEvenements.find((x) => x.pageNumber === this.currentPage)?.result ?? [];
    }

    get carouselItems(): IEvenement[] {
        let validForCaroussel = this.evenements.filter(x => x.status.code !== 'ANN');
        if (validForCaroussel.length >= 3) {
            return validForCaroussel.slice(0, 3);
        }
        return validForCaroussel;
    }

    get footerImage(): string {
        let result = `${window.location.origin}/assets/images/footer.png`;
        return result;
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles';

@include media-breakpoint-down(xl) {
    .inouit-intro {
        background: $c-main;
        padding-bottom: 20px;
        .inouit-intro-content {
            .no-transform {
                max-width: 100%;
            }
        }
    }
}
.container.inouit-container.inouit-main-container {
    padding: 0;
}

.no-event-div {
    padding: 0px 75px;
    p {
        font-size: 30px;
        line-height: 35px;
    }
}

.inouit-intro .inouit-intro-content {
    padding: 0;
    .no-transform {
        transform: none;
    }
}
.rdvmaf-accueil {
    max-width: 100%;
    overflow: hidden;

    .rdvmaf-accueil-content {
        @include media-breakpoint-up(xl) {
            margin-top: 50px;
        }
        .rdvmaf-accueil-spinner {
            margin: 15px;
            display: flex;
            justify-content: center;
        }
        .liste-title {
            font-family: $museo-slab;
            font-weight: 500;
            line-height: 40px;
            margin-bottom: 48px;
            @include media-breakpoint-up(xl) {
                font-size: 32px;
            }
            @include media-breakpoint-down(xl) {
                font-size: 20px;
                padding: 15px;
            }
        }
    }
}
.attestation-link {
    outline: 1px solid #E0E0E0;
    padding: 30px;
    margin: 15px 1px 15px 15px;
    height: 272px;
    @media (max-width: 767.98px) {
        margin: 15px 15px 15px 15px;
    }
    .rdvmaf-icon{
        margin-bottom: 20px;
    }
    a{
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $c-primary;
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
        .rdvmaf-icon{
            margin-bottom: 0px;
            margin-left: 10px;
            filter: invert(38%) sepia(99%) saturate(362%) hue-rotate(124deg) brightness(89%) contrast(93%);
        }
    }
}
</style>
